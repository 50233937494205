
export default {
  name: 'ConfirmDialog',
  data() {
    return {
      configuration: {
        title: null,
        message: null,
        htmlText: null,
        callback: null,
        buttons: null,
        noButtonLabel: 'No',
        yesButtonLabel: 'Yes',
        deleteMode: false,
        bulkAction: false,
        showWarning: false,
        customComponent: null,
        customProps: null,
        customData: null,
        beiqProfiler: false,
      },
      isOpen: false,
      confirmWord: null,
    };
  },
  computed: {
    dialogueWidth() {
      if (this.configuration.size?.width === 'small') {
        return 'max-w-lg';
      } else {
        return 'max-w-max';
      }
    },
    gridColumns() {
      const numberOfButtons = this.modalButtons.length;
      return `grid-cols-${numberOfButtons}`;
    },
    canProceed() {
      if (this.configuration.deleteMode === true) {
        return this.confirmWord === 'delete';
      } else if (this.configuration.bulkAction === true) {
        return this.confirmWord === 'action';
      } else {
        return true;
      }
    },
    modalButtons() {
      let buttons = [];
      if (this.configuration.buttons.length === 0) {
        buttons.push({
          label: this.configuration.hideNoButton ? 'Ok' : this.configuration.yesButtonLabel || 'Yes',
          isCancel: false,
          disabled: !this.canProceed,
          class: `text-p-green hover:bg-p-green-pale hover:text-p-green-dark ${
            this.configuration.hideNoButton ? 'col-span-2' : ''
          } ${!this.canProceed ? 'disabled cursor-not-allowed' : ''}`,
          callback: () => {
            if (this.configuration.callback) {
              this.confirmWord = null;
              if (this.customData) {
                this.configuration.callback(this.customData);
              } else {
                this.configuration.callback();
              }
            }
            this.isOpen = false;
          },
        });
      } else {
        buttons = this.configuration.buttons;
      }

      if (!this.configuration.hideNoButton) {
        buttons.unshift({
          label: this.configuration.noButtonLabel || 'No',
          isCancel: true,
          disabled: false,
          class: 'text-p-pink hover:bg-p-pink-pale hover:text-p-pink-dark',
          callback: () => {
            this.configuration.callback();
            this.isOpen = false;
            this.confirmWord = null;
          },
        });
      }

      return buttons;
    },
  },
  mounted() {
    this.$nuxt.$on('confirm', this.open);
  },
  beforeDestroy() {
    this.$nuxt.$off('confirm');
  },
  methods: {
    open(config) {
      if (!config.buttons) {
        config.buttons = [];
      }

      this.configuration = config;
      this.isOpen = true;
    },
    handleButtonClick(button) {
      if (button.callback) {
        if (this.customData) {
          button.callback(this.customData);
        } else {
          button.callback(button.value);
        }
      }
      this.isOpen = false;
    },
    handleCustomEvent(data) {
      this.customData = data;
    },
    async clickToCopy(text, alert) {
      await navigator.clipboard.writeText(text);
      this.$nuxt.$emit('notification', alert);
    },
  },
};
